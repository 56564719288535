@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-darkBg text-white font-montserrat;
  width: 100%;
  height: 100vh;
}


.intl-tel-input {
  width: 100%;
}

.swiper {
  z-index: 0 !important;
}

.country-name {
  color: black;
}

.overflow-y-scroll::-webkit-scrollbar {
  display: none;
}